<template lang="pug">
  transition(name="slide-fade")
    .home
      loading(:active.sync="busy", :is-full-page="true")
      .widget
        .widget-header
          h1.title Post Attribution Reports
        .widget-body
          form.form(@submit.prevent="uploadReport()")
            .row
              .col-sm-4
                .form-group
                  label Upload PA report
                  doc-list(:files="report", :single="true", :cb="onReportReady")
              .col-sm-2
                .form-group
                  label &nbsp;
                  div
                    b-button(type="submit", variant="primary", :disabled="!file") Upload

          form(@submit.prevent="loadPaData()") 
            .form-group
              .row
                .col-sm-4
                  label MMP Account
                  select.form-control(v-model="filters.mmpAccountId")
                    option(:value="null") -
                    option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
                .col-sm-2
                  .form-group
                    label &nbsp;
                    div
                      b-button(type="submit", variant="primary") Go

          p.clearfix &nbsp;
          .ov-table-wrapper
            table.table.table-bordered
              thead
                tr
                  th ID
                  th File Name
                  th Date
                  th MMP Account
                  th Status
                  th Error
                  th Actions
              tbody
                tr(v-for="r in paReports")
                  td {{ r.id }}
                  td
                    table-editable-cell(:value="r.file_name", :on-save-target="(newName) => onSave({ ...r, file_name: newName })")
                  td {{ r.date }}
                  td
                    entity(v-if="r.mmpAccount", type='mmp-account', :id="r.mmpAccount.id", :name="r.mmpAccount.account_name")
                  td
                    span.badge.badge-secondary(v-if="r.status === 'new'") New
                    span.badge.badge-primary(v-if="r.status === 'process'") Process
                    span.badge.badge-danger(v-if="r.status === 'error'") Error
                    span.badge.badge-success(v-if="r.status === 'done'") Done
                  td
                    .error-message {{ r.error_message }}
                  td.actions
                    .action-buttons
                      span.btn.btn-action-info(v-on:click="downloadPaReport(r.id)", v-b-tooltip.hover.bottom, title="Download report")
                        a.no-link.text-white(v-if="r.file_path", :href="r.file_path", target="_blank")
                          i.la.la-download
          paginate(:paginator="paPaginate", @update-page-size="loadData")
</template>
<style lang="scss" scoped>
.badge {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}

.error-message {
  max-height: 200px;
  overflow: auto;
}
</style>
<script>
export default {
  name: 'PostAttribution',
  data() {
    return {
      busy: false,
      file: null,
      report: [], // To make doc-list work properly

      mmpAccountOptions: [],
      filters: {
        mmpAccountId: null,
      },

      paReports: [],
      paPaginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadPaData();
        },
      },
    };
  },
  methods: {
    async loadPaData() {
      this.busy = true;
      const params = {
        page: this.paPaginate.page,
        page_size: this.paPaginate.limit,
      };
      if (this.filters.mmpAccountId) {
        params.mmp_account_id = this.filters.mmpAccountId;
      }
      try {
        const resp = await this.$ovReq.get('click/pa-report/getList', { params });
        this.paReports = resp.records;
        this.paPaginate.total = resp.total;
        this.paPaginate.numPages = Math.max(
          Math.ceil(this.paPaginate.total / this.paPaginate.limit),
          1,
        );
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async uploadReport() {
      if (this.busy) return;
      if (!this.file) return;

      this.busy = true;

      try {
        await this.$ovReq.post('click/pa-report/upload', {
          path: this.file.full_path,
          name: this.file.name,
          mmp_account_id: this.mmpAccountId,
        });
        this.busy = false;
        this.file = null;
        await this.loadPaData();
        this.$ovNotify.success('Report uploaded successfully');
      } catch (e) {
        console.error(e);
      }

      this.busy = false;
    },

    async onSave(report) {
      try {
        await this.$ovReq.post('click/pa-report/save', report);
        this.$ovNotify.success('Report updated successfully');
        await this.loadPaData();
      } catch (e) {
        console.error(e);
      }
    },

    onReportReady(file) {
      this.file = file;
    },
  },
  async mounted() {
    const [mmpAccountOptions] = await Promise.all([
      this.$ovData.mmpAccount.getOptions(true),
      this.loadPaData(),
    ]);
    this.mmpAccountOptions = mmpAccountOptions;
  },
};
</script>
