<template lang="pug">
	transition(name="slide-fade")
		.home
			loading(:active.sync="busy", :is-full-page="true")
			.widget
				.widget-header
					h1.title Clicks
				.widget-body
					.row
						.col-sm-6
							form.form(@submit.prevent="loadData()")
								click-list(v-model="filters.clicks", label="Clicks")
								.form.form-inline.ov-filters
									.form-row-main
										.form-group.no-label
											label.form-check-label
												input.form-check-input(type="checkbox", v-model="filters.reported")
												| Reported Only
										.form-group
											label &nbsp;
											//button.btn.btn-primary(type="submit") Submit
											b-button-group
												b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
													b-dropdown-item(@click="loadData(true)")
														i.la.la-download
														| Export

						.col-sm-6(v-if="!filters.reported")
							div Found {{ records.length }} records
							div Active: {{ summary.active }}
							div Inactive: {{ summary.inactive }}
							div Missing: {{ summary.missing }}
							//.form-row-end
								.form-group
									label &nbsp;
									button.btn.btn-secondary(type="button", @click="unblockSelected()") Unblock Selected
								.form-group
									label &nbsp;
									button.btn.btn-secondary(type="button", @click="blockSelected()") Block Selected

					p.clearfix &nbsp;
					.ov-table-wrapper
						table.table.table-bordered.table-condensed
							thead
								tr
									//th.text-center(style="width: 40px;")
										input(type="checkbox", :checked="allChecked", @click="toggleAll()")
									th &nbsp;
									th ID
									th Placement
									th Publisher
									th Offer
									th Advertiser
									th C.Type
									th IP
									th Country
									th AffClickID
									th Sub1
									th Sub2
									th Sub3
									th Adv Sub1
									th AppName
									th BundleId
									th IDFA
									th GAID
									th Click Time
									th Conv. Time
									th CTIT
									th Reported
									th S
									th &nbsp;
							tbody
								tr(v-for="r in selectedRecords")
									//td.text-center
										input(type="checkbox", v-model="r._selected")
									td
										span(v-if="r.click_status==='active'")
											i.la.la-check
										span(v-if="r.click_status==='inactive'")
											i.la.la-minus
										span(v-if="r.click_status==='missing'")
											i.la.la-times
									td {{ r.id }}
									td
										span(v-if="r.placement")
											entity(type="placement", :id="r.placement_id", :name.sync="r.placement.name", :data="r.placement")
									td
										span(v-if="r.publisher")
											entity(type="publisher", :id="r.publisher_id", :name.sync="r.publisher.name")
									td
										span(v-if="r.offer")
											entity(type="offer", :id="r.offer_id", :name.sync="r.offer.name")
									td
										span(v-if="r.advertiser")
											entity(type="advertiser", :id="r.advertiser_id", :name.sync="r.advertiser.name")
									td {{ r.click_type }}
									td {{ r.ip_fmt }}
									td {{ r.country }}
									td {{ r.aff_clickid }}
									td {{ r.sub1 }}
									td {{ r.sub2 }}
									td {{ r.sub3 }}
									td {{ r.adv_sub1 }}
									td {{ r.app_name }}
									td {{ r.bundle_id }}
									td {{ r.idfa }}
									td {{ r.gaid }}
									td {{ r.created_at }}
									td
										span(v-if="r.conversion") {{ r.conversion.created_at }}
									td {{ r.ctit_fmt }}
									td
										span(v-if="r.conversion") {{ r.conversion.report_time }}
									td {{ r.id_ts_mod }}
									td.actions
										router-link(:to="{name:'click-view', params: {id:r.id}}")
											i.la.la-search

</template>
<style lang="scss" scoped>
.badge {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'ClickSearch',
  components: {
    // Graph
  },
  data() {
    return {
      busy: false,
      allChecked: false,
      records: [],
      summary: {
        active: 0,
        inactive: 0,
        missing: 0,
      },
      filters: {
        reported: false,
        timezone: 0,
        clicks: '',
      },
    };
  },
  computed: {
    selectedRecords() {
      return this.records.filter((r) => {
        return !this.filters.reported || (r.conversion && r.conversion.report_time);
      });
    },
  },
  methods: {
    resetSummary() {
      this.summary = {
        active: 0,
        inactive: 0,
        missing: 0,
      };
    },
    toggleAll() {
      this.allChecked = !this.allChecked;
      this.records.forEach((r) => {
        r._selected = this.allChecked;
      });
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      this.resetSummary();
      let clicks = this.filters.clicks;
      let clicksList = clicks.split('\n');
      let records = [];
      this.busy = true;
      if (clicksList.length > 0) {
        if (doExport) {
          let params = {
            reported: this.filters.reported ? 1 : 0,
            clicks: clicks,
            export: 1,
          };
          try {
            let resp = await this.$ovReq.post('click/search', params);
            window.open(resp.path);
          } catch (e) {
            console.error(e);
          }
        } else {
          let offset = 0;
          let limit = 50;
          while (offset < clicksList.length) {
            let slice = clicksList.slice(offset, offset + limit);

            let params = {
              reported: this.filters.reported ? 1 : 0,
              clicks: slice.join('\n'),
            };
            try {
              let resp = await this.$ovReq.post('click/search', params);
              resp.records.forEach((r) => {
                records.push(r);
              });
              this.summary.active += resp.summary.active;
              this.summary.inactive += resp.summary.inactive;
              this.summary.missing += resp.summary.missing;
            } catch (e) {
              console.error(e);
            }

            offset += limit;
          }

          this.records = records;
          this.records.forEach((r) => {
            Vue.set(r, '_selected', false);
          });
        }
      }

      this.busy = false;
    },
  },
  mounted() {},
};
</script>
